<template>
    <page-title title="Contact"/>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body mb-4 p-0">
                    <iframe class="map"
                            src="https://maps.google.com/maps?hl=en&q=MIG 63, JANTA QUATER, Nehru Nagar, Bilaspur, Chhattisgarh 495001 Bilaspur+(Bilaspur law academy)&z=20&ie=UTF8&iwloc=B&output=embed"></iframe>
                </div>
                <div class="card-body">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-4 col-lg-auto text-center mb-4" v-for="(item,index) in social" :key="index">
                            <a class="btn btn-lg btn-soft-primary btn-icon-circle-sm mb-2"
                               :href="item.link" target="_blank"
                            >
                                <i :class="item.icon" class="fa-1x"></i>
                            </a>
                            <p class="mb-0 font-weight-semibold">{{item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PageTitle from "@/components/UI/Theme/PageTitle";


export default {
    name: "contact",
    components: {PageTitle},
    data() {
        return {
            social:[
                {
                    name:"Phone",
                    link:"tel:917987703010",
                    icon:"fas fa-phone"
                },{
                    name:"Email",
                    link:"mailto:info@bilaspurlawacademy.com",
                    icon:"far fa-envelope-open"
                },{
                    name:"Website",
                    link:"https://bilaspurlawacademy.com/",
                    icon:"fas fa-globe"
                },{
                    name:"Facebook",
                    link:"https://www.facebook.com/bilaspurlawacademy",
                    icon:"fab fa-facebook-f"
                },{
                    name:"Instagram",
                    link:"https://www.instagram.com/bilaspur_law_academy/",
                    icon:"fab fa-instagram"
                },{
                    name:"Youtube",
                    link:"https://youtube.com/channel/UCUICvvVHzgEfwsqiGwySOUw",
                    icon:"fab fa-youtube"
                },{
                    name:"Whatsapp",
                    link:"https://wa.me/917987703010/",
                    icon:"fab fa-whatsapp"
                },
            ]
        }
    }
}
</script>

<style scoped>
.map{
    width: 100%;
    height: 450px;
    border: none;
    /*border-bottom-left-radius: 10%;*/
    /*border-bottom-right-radius: 10%;*/
}
</style>